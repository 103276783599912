import { useEffect, useState } from 'react';
import  secureLocalStorage  from  "react-secure-storage";
import styles from './styles.module.scss';
import Image from 'next/image';

const year = new Date().getFullYear();

export default function Footer() {
    
    const [lang, setLang] = useState(null);
    useEffect(() => {
        setLang(secureLocalStorage.getItem("lang"));
    }, []);
    return (
        <div className={styles.footer}>
            <div className={styles.bg}></div>
            <div className={styles.cnt}>

            {lang == 'en' || lang == '' ? <><div className={styles.cf}></div>
                    <div className={styles.euEn}></div></> : ''}
            
            {lang == 'zh-Hant-TW' ? <><div className={styles.cf}></div>
                    <div className={styles.euTaiwan}></div></> : ''}

            {lang == 'ko' ? <><div className={styles.cfKorea}></div>
                    <div className={styles.euKorea}></div></> : ''}

            {lang == 'zh' ? <><div className={styles.cfChina}></div>
                    <div className={styles.euChina}></div></> : ''}

                <p>{year}©</p>
                <figure>
                    <div className={styles.eie}></div>
                </figure>
            </div>
        </div>
    );
}
