import React, { useState, useEffect } from "react";
import "@/styles/style.scss";
import Header from "@/components/header";
import Head from "next/head";
import Footer from "@/components/footer";
import  secureLocalStorage  from  "react-secure-storage";
import CookieBot from "@/components/cookiebot";
import "@/styles/cookiebot.scss";


export default function App({ Component, pageProps }) {
    const size = useWindowSize();
    const [data, setData] = useState([]);
    const dataCommon = () => {
        (async() => {
            
            const lang = secureLocalStorage.getItem("lang");
            try {
                const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/common?populate=head&locale=${lang}`).then((res) => res.json());
                // console.log(data);
                setData(data.data.attributes);
            } catch(err) {
                console.log(err)
            }
        })();
    }
    useEffect(() => {
        dataCommon();
        const main = document.querySelector('.main');
        main.style.minHeight = `${size.height}px`;
    }, [size]);

    return (
        <>
            <Head>
                <title>{data?.head?.title}</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                />
                <meta
                    name="description"
                    content={data?.head?.metaDescription}
                />
                <meta name="keywords" content={data?.head?.metaKeywords} />
                <link rel="icon" type="image/png" href="/favicon.png" />
            </Head>
            <div className="main">
                <Header />
                <CookieBot />
                <main>
                    <Component {...pageProps} />
                </main>
                <Footer />
            </div>
        </>
    );
}


function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      // only execute all the code below in client side
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      
      // Add event listener
      window.addEventListener("resize", handleResize);
       
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }