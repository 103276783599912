import Script from 'next/script';

export default function CookieBot() {
  return (
    <>
      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="db80fe54-429a-4b86-8df6-4faebc44f33f"
        data-blockingmode="manual"
      />
      <Script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/db80fe54-429a-4b86-8df6-4faebc44f33f/cd.js"
        async
      />
    </>
  );
}

