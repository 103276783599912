import styles from './styles.module.scss';
import { useEffect, useState } from 'react';


export default function Slop({color}) {
    
    useEffect(() => {
        // console.log(color);
      });
    return (
        <div className={`${styles.slop} ${styles[color]}`}>
            <div className={`${styles.bgSlop}`}></div>
        </div>
    );
}

